import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './HistoryPage.css';

function HistoryPage() {
  const [historyData, setHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [favoriteProduct, setFavoriteProduct] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [minTopUp, setMinTopUp] = useState('');
  const [maxTopUp, setMaxTopUp] = useState('');
  const [minExpense, setMinExpense] = useState('');
  const [maxExpense, setMaxExpense] = useState('');
  const [hideTopUps, setHideTopUps] = useState(false);
  const [hideExpenses, setHideExpenses] = useState(false);
  const [hideMembershipPayments, setHideMembershipPayments] = useState(false);

  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const selectedClub = location.state?.club; // Retrieve club from navigation state

  useEffect(() => {
    const fetchHistory = async () => {
      if (!selectedClub) {
        setMessage('No club specified');
        return;
      }

      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://vlcweed.me/history?club=${selectedClub}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = Array.isArray(response.data) ? response.data : response.data.data;
        setHistoryData(data);
        setFilteredData(data);
        calculateFavoriteProduct(data);
      } catch (error) {
        setMessage('Error retrieving history');
      }
    };

    fetchHistory();
  }, [selectedClub]);

  const calculateFavoriteProduct = (data) => {
    const productCount = {};

    data.forEach(record => {
      if (record.Товары && record.Товары.length > 0) {
        record.Товары.forEach(item => {
          const productName = item.Номенклатура || 'Unknown Product';
          productCount[productName] = (productCount[productName] || 0) + (item.Количество || 1);
        });
      }
    });

    let favorite = null;
    let maxCount = 0;
    for (const [product, count] of Object.entries(productCount)) {
      if (count > maxCount) {
        favorite = product;
        maxCount = count;
      }
    }

    setFavoriteProduct(favorite);
  };

  const toggleFilterPopup = () => {
    setShowFilter(!showFilter);
  };

  const applyFilters = () => {
    const filtered = historyData.filter(record => {
      const recordDate = record.ДокументДата ? new Date(record.ДокументДата.split("-").reverse().join("-")) : null;
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;

      const isAfterStartDate = start ? recordDate >= start : true;
      const isBeforeEndDate = end ? recordDate <= end : true;

      const topUpAmount = parseFloat(record.СуммаПополнения) || 0;
      const expenseAmount = parseFloat(record.СуммаСписания) || 0;

      const meetsTopUpFilter = 
        (!minTopUp || topUpAmount >= parseFloat(minTopUp)) &&
        (!maxTopUp || topUpAmount <= parseFloat(maxTopUp));

      const meetsExpenseFilter = 
        (!minExpense || expenseAmount >= parseFloat(minExpense)) &&
        (!maxExpense || expenseAmount <= parseFloat(maxExpense));

      const shouldHideTopUp = hideTopUps && topUpAmount > 0;
      const shouldHideExpense = hideExpenses && expenseAmount > 0;
      const isMembershipPayment = isOnlyDate(record);
      const shouldHideMembershipPayment = hideMembershipPayments && isMembershipPayment;

      return (
        isAfterStartDate &&
        isBeforeEndDate &&
        meetsTopUpFilter &&
        meetsExpenseFilter &&
        !shouldHideTopUp &&
        !shouldHideExpense &&
        !shouldHideMembershipPayment
      );
    });
    
    setFilteredData(filtered);
    setShowFilter(false);
  };
  
  const isOnlyDate = (record) => {
    return (
      record.ДокументДата &&
      (!record.СуммаПополнения || parseFloat(record.СуммаПополнения) === 0) &&
      (!record.СуммаСписания || parseFloat(record.СуммаСписания) === 0) &&
      (!record.СуммаВзносов || parseFloat(record.СуммаВзносов) === 0) &&
      (!record.Товары || record.Товары.length === 0)
    );
  };

  return (
    <div className="history-page">
      <div className="history-container">
        <div className="top-bar">
          <button onClick={() => navigate(-1)} className="back-button" aria-label="Back">
            <svg width="34px" height="34px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#36d33b" stroke-width="20">
                <circle cx="256" cy="256" r="246" stroke-linecap="round" stroke-linejoin="round"></circle>
                <line x1="352.26" x2="170.43" y1="256" y2="256" stroke-linecap="round" stroke-linejoin="round"></line>
                <polyline points="223.91 202.52 170.44 256 223.91 309.48" stroke-linecap="round" stroke-linejoin="round"></polyline>
            </svg>
          </button>
          <h1>{selectedClub} History</h1>
          <button onClick={toggleFilterPopup} className="filter-button" aria-label="Filters">
            <svg width="34px" height="34px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier"  strokeWidth="1.5"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 5L10 5M10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5M14 5L20 5M4 12L16 12M16 12C16 13.1046 16.8954 14 18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12ZM8 19L20 19M8 19C8 17.8954 7.10457 17 6 17C4.89543 17 4 17.8954 4 19C4 20.1046 4.89543 21 6 21C7.10457 21 8 20.1046 8 19Z" stroke="#36d33b" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>
          </button>
        </div>
        {favoriteProduct && (
          <div className="favorite-product-container">
            <div className="favorite-product-card">
              <h3 className="favorite-product-title">Your Favorite Product</h3>
              <p className="favorite-product-name">{favoriteProduct}</p>
            </div>
          </div>
        )}
        {showFilter && (
            <div className="overlay">
                
                <div className="filter-popup">
                <h3>Filters</h3>
                <label>
                    Start Date:
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </label>
                <label>
                    End Date:
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </label>
                <label>
                    Min Top-up:
                    <input type="number" value={minTopUp} onChange={(e) => setMinTopUp(e.target.value)} />
                </label>
                <label>
                    Max Top-up:
                    <input type="number" value={maxTopUp} onChange={(e) => setMaxTopUp(e.target.value)} />
                </label>
                <label>
                    Min Expense:
                    <input type="number" value={minExpense} onChange={(e) => setMinExpense(e.target.value)} />
                </label>
                <label>
                    Max Expense:
                    <input type="number" value={maxExpense} onChange={(e) => setMaxExpense(e.target.value)} />
                </label>
                <label>
                    <input type="checkbox" checked={hideTopUps} onChange={(e) => setHideTopUps(e.target.checked)} />
                    Hide Top-ups
                </label>
                <label>
                    <input type="checkbox" checked={hideExpenses} onChange={(e) => setHideExpenses(e.target.checked)} />
                    Hide Expenses
                </label>
                <label>
                    <input type="checkbox" checked={hideMembershipPayments} onChange={(e) => setHideMembershipPayments(e.target.checked)} />
                    Hide Membership Payments
                </label>
                <button onClick={applyFilters}>Apply Filters</button>
                </div>
            </div>
        )}
        {filteredData.length === 0 ? (
          <p>{message || 'No data to display'}</p>
        ) : (
          <ul className="history-list">
            {filteredData.map((record, index) => (
              <li key={index} className={isOnlyDate(record) ? 'membership-item' : 'history-item'}>
                <p><strong>Date:</strong> {record.ДокументДата || 'Not specified'}</p>
                {isOnlyDate(record) ? (
                  <p>Membership Payment</p>
                ) : (
                  <>
                    <p className={!record.СуммаПополнения || parseFloat(record.СуммаПополнения) === 0 ? 'hidden' : ''}>
                      <strong>Top-up amount:</strong> {record.СуммаПополнения} Tokens
                    </p>
                    <p className={!record.СуммаСписания || parseFloat(record.СуммаСписания) === 0 ? 'hidden' : ''}>
                      <strong>Expense amount:</strong> {record.СуммаСписания} Tokens
                    </p>
                  </>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default HistoryPage;
