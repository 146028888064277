import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Login.css';
import { useNavigate } from 'react-router-dom';

function Login({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [clubs, setClubs] = useState([]); // Список клубов пользователя
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/profile'); // Если токен есть, перенаправляем на профиль
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://vlcweed.me/login', { email, password });
      const newToken = response.data.token;
      localStorage.setItem('token', newToken); // Сохраняем токен в localStorage
      onLogin(newToken);

      // Сохраняем список клубов и переходим на профиль
      setClubs(response.data.clubs);
      navigate('/profile', { state: { clubs: response.data.clubs } });
      setMessage('Success');
    } catch (error) {
      if (error.response && error.response.data.message === 'Email not verified') {
        setMessage('Please verify your email.');
      } else {
        setMessage('Authorization error. Check data.');
      }
    }
  };

  const handleRegisterRedirect = () => {
    navigate('/register'); // Перенаправляем на страницу регистрации
  };

  return (
    <div className="login-page">
      <h2 className="login-title">Login</h2>
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            className="input-field"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            className="input-field"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="login-button">Login</button>
        </form>
        <p className="login-message">{message}</p>
        
        <button onClick={handleRegisterRedirect} className="register-button">
          Registration
        </button>
      </div>
    </div>
  );
}

export default Login;
