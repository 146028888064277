import React, { useEffect, useState } from 'react';
import './AddToHomeScreenInstructions.css';

function AddToHomeScreenInstructions() {
  const [isMobile, setIsMobile] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [isStandalone, setIsStandalone] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setIsMobile(true);
      setPlatform('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsMobile(true);
      setPlatform('iOS');
    }

    setIsStandalone(
      window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone
    );
  }, []);

  if (!isMobile || isStandalone || !showInstructions) {
    return null;
  }

  return (
    <div className="overlay">
      <div className="add-to-home-instructions">
        <button onClick={() => setShowInstructions(false)} className="close-button" aria-label="Close">
          <svg width="24px" height="24px" viewBox="0 0 24 24" fill="#49c14d" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" stroke="#49c14d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </button>
        {platform === 'iOS' ? (
          <div>
            <p>To add this app to your home screen on iOS:</p>
            <div className="instruction-step">
              <p>1. Tap the <strong>Share</strong> button:</p>
              <span className="instruction-icon">
                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 13V17.5C20 20.5577 16 20.5 12 20.5C8 20.5 4 20.5577 4 17.5V13M12 3L12 15M12 3L16 7M12 3L8 7" stroke="#49c14d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </span>
            </div>
            <div className="instruction-step">
              <p>2. Then select <strong>"Add to Home Screen"</strong>.</p>
            </div>
          </div>
        ) : (
          <div>
            <p>To add this app to your home screen on Android:</p>
            <div className="instruction-step">
              <p>1. Tap the <strong>Menu</strong> button:</p>
              <span className="instruction-icon">
                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 5H20M4 12H20M4 19H20" stroke="#49c14d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </span>
            </div>
            <div className="instruction-step">
              <p>2. Then select <strong>"Add to Home Screen"</strong>.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddToHomeScreenInstructions;
