import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Register.css';
import { useNavigate } from 'react-router-dom';

function Register() {
  const [documentNumber, setDocumentNumber] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const [availableClubs, setAvailableClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCheckUser = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('https://vlcweed.me/check-user', { documentNumber });
      const data = response.data;

      if (data.found) {
        if (data.existing && data.clubs.length === 0) {
          setMessage('User already registered in all clubs. Please log in.');
        } else {
          setUserInfo({
            name: data.name || data.clubs[0]?.name,
            lastName: data.lastName || data.clubs[0]?.lastName,
            email: data.email || data.clubs[0]?.email,
          });
          setAvailableClubs(data.clubs || []);
          setMessage('');
        }
      } else {
        setMessage('No such user has been found');
      }
    } catch (error) {
      setMessage('Error checking the user');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectClub = (club) => {
    setSelectedClub(club);
    setUserInfo({
      name: club.name,
      lastName: club.lastName,
      email: club.email,
    });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords don't match");
      return;
    }
    try {
      await axios.post('https://vlcweed.me/register', {
        email: userInfo.email,
        password,
        firstName: userInfo.name,
        lastName: userInfo.lastName,
        documentNumber,
        club: selectedClub.club,
      });
      setMessage('The user has been successfully registered. Check Email for verification');
      
      setTimeout(() => {
        navigate('/login');
      }, 1500);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage(error.response.data);
      } else {
        setMessage('Error during registration');
      }
    }
  };

  const handleReset = () => {
    setUserInfo(null);
    setDocumentNumber('');
    setPassword('');
    setConfirmPassword('');
    setSelectedClub(null);
    setAvailableClubs([]);
    setMessage('');
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  useEffect(() => {
    console.log('User info updated:', userInfo);
  }, [userInfo]);

  return (
    <div className="register-page">
      <h2 className="register-title">Club Access</h2>
      <div className="register-container">
        {!userInfo && !selectedClub ? (
          <form onSubmit={handleCheckUser}>
            <input 
              type="text" 
              className="input-field" 
              placeholder="Document number" 
              value={documentNumber} 
              onChange={(e) => setDocumentNumber(e.target.value)} 
            />
            <button type="submit" className="check-button">Check</button>
            {isLoading && (
              <img src="/assets/loading.gif" alt="Loading..." className="loading-indicator" />
            )}
          </form>
        ) : !selectedClub ? (
          <div className="club-selection">
            <p>Select the club where you want to register:</p>
            <div className="club-list">
              {availableClubs.map((club, index) => (
                <div 
                  key={index} 
                  className="club-card" 
                  onClick={() => handleSelectClub(club)}
                >
                  {/* Загрузка иконки клуба */}
                  <img 
                    src={`/assets/${club.club.toLowerCase()}.png`} 
                    alt={`${club.club} Icon`} 
                    className="club-icon-reg" 
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <form onSubmit={handleRegister}>
            <input 
              type="text" 
              className="input-field" 
              value={userInfo?.name || ''} 
              readOnly 
            />
            <input 
              type="text" 
              className="input-field" 
              value={userInfo?.lastName || ''} 
              readOnly 
            />
            <input 
              type="email" 
              className="input-field" 
              value={userInfo?.email || ''} 
              readOnly 
            />
            <div className="password-field">
              <input 
                type={showPassword ? 'text' : 'password'}
                className="input-field" 
                placeholder="Your password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
              <span 
                className="toggle-password" 
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? '🙈' : '👁️'}
              </span>
            </div>
            <div className="password-field">
              <input 
                type={showPassword ? 'text' : 'password'}
                className="input-field" 
                placeholder="Confirm password" 
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
              />
            </div>
            <button type="button" className="reset-button" onClick={handleReset}>Isn't that you?</button>
            <button type="submit" className="register-button">Register</button>
          </form>
        )}
        <p className="message">{message}</p>
        <button onClick={handleLoginRedirect} className="check-button">
          Login
        </button>
      </div>
    </div>
  );
}

export default Register;
