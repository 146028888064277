import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Profile.css';
import { useNavigate, Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
function Profile() {
  const [profileData, setProfileData] = useState(null);
  const [clubs, setClubs] = useState([]); // Список клубов пользователя
  const [selectedClub, setSelectedClub] = useState(null);
  const [message, setMessage] = useState('Loading profile...');
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0); // Index for carousel

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://vlcweed.me/profile', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data;
        setProfileData(data);
        setClubs(data.clubs || []); // Сохраняем список клубов
        setMessage(data.message || 'Select a Club');
      } catch (error) {
        setMessage('Error loading profile data');
        localStorage.removeItem('token');
        navigate('/login');
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleClubSelect = async (club) => {
    setSelectedClub(club);
    setMessage('Loading club profile...'); // Показать сообщение о загрузке
    setProfileData(null); // Очистить старые данные профиля, чтобы избежать ошибок

    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`https://vlcweed.me/profile?club=${club}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProfileData(response.data);
    } catch (error) {
      setMessage('Error loading club profile');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleChangeClub = () => {
    setSelectedClub(null); // Сбрасываем выбранный клуб для отображения выбора клубов
    setProfileData(null); // Сбрасываем данные профиля
    setMessage('Select a Club');
  };
// Carousel navigation functions
const nextClub = () => {
  setCurrentIndex((prevIndex) => (prevIndex + 1) % clubs.length);
};

const prevClub = () => {
  setCurrentIndex((prevIndex) => (prevIndex - 1 + clubs.length) % clubs.length);
};

// Swipe handlers
const swipeHandlers = useSwipeable({
  onSwipedLeft: nextClub,
  onSwipedRight: prevClub,
  trackMouse: true, // Allows mouse swipe for testing on desktop
});
const handleCheckInOtherClub = () => {
  navigate('/register');
};
  const avatarSrc = profileData?.gender === 'Hombre'
    ? '/assets/male-avatar.jpg'
    : profileData?.gender === 'Mujer'
      ? '/assets/female-avatar.jpg'
      : '/assets/default-avatar.jpg';

  return (
    <div className="profile-page">
      {selectedClub ? (
        <div className="profile-container">
          <div className="profile-header">
            <div className="club-selected">
              <span className="club-name">{selectedClub}</span>
              <button onClick={handleChangeClub} className="change-club-button" aria-label="Change Club">
                <svg width="34px" height="34px" viewBox="0 -0.5 17 17" fill="#47c24b" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.539,8.001 C2.367,8.001 2.918,7.45 2.918,6.622 L2.914,6.187 C2.914,6.187 2.709,4.954 4.354,4.954 L11.015,5 L11.015,6.759 C11.338,7.081 11.862,7.081 12.185,6.759 L14.758,4.187 C15.08,3.863 15.08,3.339 14.758,3.017 L12.185,0.292 C11.862,-0.03 11.338,-0.03 11.015,0.292 L11.015,2.137 C10.854,2.09 4.562,2.063 4.562,2.063 C0.851,2.063 0.039,4.492 0.039,5.47 L0.039,6.501 C0.039,7.329 0.711,8.001 1.539,8.001 L1.539,8.001 Z "strokeWidth="1.5"></path>
                  <path d="M13.5,8.042 C12.672,8.042 12,8.626 12,9.454 L12.002,10.411 C11.957,10.768 11.357,11.001 10.477,11.001 L3.938,11.001 L3.936,9.442 C3.614,9.12 3.09,9.12 2.766,9.442 L0.194,12.014 C-0.128,12.338 -0.128,12.862 0.194,13.184 L2.766,15.756 C3.09,16.08 3.614,16.08 3.936,15.756 L3.938,13.905 L10.477,13.905 C14.188,13.905 15,11.463 15,10.484 L15,9.453 C15,8.626 14.328,8.042 13.5,8.042 L13.5,8.042 Z" strokeWidth="1.5"></path>
                </svg>
              </button>
            </div>
            <button onClick={handleLogout} className="logout-button" aria-label="Logout">
              <svg width="34px" height="34px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.00195 7C9.01406 4.82497 9.11051 3.64706 9.87889 2.87868C10.7576 2 12.1718 2 15.0002 2L16.0002 2C18.8286 2 20.2429 2 21.1215 2.87868C22.0002 3.75736 22.0002 5.17157 22.0002 8L22.0002 16C22.0002 18.8284 22.0002 20.2426 21.1215 21.1213C20.2429 22 18.8286 22 16.0002 22H15.0002C12.1718 22 10.7576 22 9.87889 21.1213C9.11051 20.3529 9.01406 19.175 9.00195 17" stroke="#3fc643" stroke-width="1.5" stroke-linecap="round"></path>
                <path d="M15 12L2 12M2 12L5.5 9M2 12L5.5 15" stroke="#3fc643"  strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </button>
                        
            <Link 
              to="/history" 
              state={{ club: selectedClub }} // Передача выбранного клуба через state
              className="history-link-button" 
              aria-label="History"
            >
              <svg fill="#49c14d" xmlns="http://www.w3.org/2000/svg" width="34px" height="34px" viewBox="0 0 503.379 503.379">
                <path d="M458.091,128.116v326.842c0,26.698-21.723,48.421-48.422,48.421h-220.92c-26.699,0-48.421-21.723-48.421-48.421V242.439 c6.907,1.149,13.953,1.894,21.184,1.894c5.128,0,10.161-0.381,15.132-0.969v211.594c0,6.673,5.429,12.104,12.105,12.104h220.92 c6.674,0,12.105-5.432,12.105-12.104V128.116c0-6.676-5.432-12.105-12.105-12.105H289.835c0-12.625-1.897-24.793-5.297-36.315 h125.131C436.368,79.695,458.091,101.417,458.091,128.116z M159.49,228.401c-62.973,0-114.202-51.229-114.202-114.199 C45.289,51.229,96.517,0,159.49,0c62.971,0,114.202,51.229,114.202,114.202C273.692,177.172,222.461,228.401,159.49,228.401z M159.49,204.19c49.618,0,89.989-40.364,89.989-89.988c0-49.627-40.365-89.991-89.989-89.991 c-49.626,0-89.991,40.364-89.991,89.991C69.499,163.826,109.87,204.19,159.49,204.19z M227.981,126.308 c6.682,0,12.105-5.423,12.105-12.105s-5.423-12.105-12.105-12.105h-56.386v-47.52c0-6.682-5.423-12.105-12.105-12.105 s-12.105,5.423-12.105,12.105v59.625c0,6.682,5.423,12.105,12.105,12.105H227.981z M367.697,224.456h-131.14 c-6.682,0-12.105,5.423-12.105,12.105c0,6.683,5.423,12.105,12.105,12.105h131.14c6.685,0,12.105-5.423,12.105-12.105 C379.803,229.879,374.382,224.456,367.697,224.456z M367.91,297.885h-131.14c-6.682,0-12.105,5.42-12.105,12.105 s5.423,12.105,12.105,12.105h131.14c6.685,0,12.104-5.42,12.104-12.105S374.601,297.885,367.91,297.885z M367.91,374.353h-131.14 c-6.682,0-12.105,5.426-12.105,12.105c0,6.685,5.423,12.104,12.105,12.104h131.14c6.685,0,12.104-5.42,12.104-12.104 C380.015,379.778,374.601,374.353,367.91,374.353z" strokeWidth="1.5"></path>
              </svg>
            </Link>

          </div>
          {profileData ? (
            <>
              <img src={avatarSrc} alt="User avatar" className="profile-foto" />
              <p className="profile-name"><strong>{profileData.name} {profileData.lastName}</strong></p>
              <p className="profile-dateReg">Registration date: {profileData.dateReg}</p>
              <p className="profile-credit"><strong>{profileData.credit !== undefined ? profileData.credit : 0} Tokens</strong></p>
              <p className="profile-expiry">Cuota expired: {profileData.expiry}</p>
              <div className="profile-info">
                <p className="profile-field"><strong>Gender:</strong> {profileData.gender}</p>
                <p className="profile-field"><strong>Date of birth:</strong> {profileData.dateBirth}</p>
                <p className="profile-field"><strong>Document:</strong> {profileData.documentNumber}</p>
                <p className="profile-field"><strong>Phone:</strong> {profileData.telephone}</p>
                <p className="profile-field"><strong>Email:</strong> {profileData.email}</p>
              </div>
            </>
          ) : (
            <p>{message}</p> // Сообщение о загрузке или ошибка
          )}
        </div>
      ) : (
        <div className="club-selection">
          <h3 className="login-title">Select a Club</h3>
          <div className="carousel-container" {...swipeHandlers}>
            <button onClick={prevClub} className="carousel-button left">&#8249;</button>
            <div className="club-slide">
              {clubs.length > 0 && clubs[currentIndex] && (
                <button
                  className="club-button"
                  onClick={() => handleClubSelect(clubs[currentIndex])}
                  aria-label={`Select ${clubs[currentIndex]}`}
                >
                  <img
                    src={`/assets/${clubs[currentIndex].toLowerCase()}.png`}
                    alt={`${clubs[currentIndex]} icon`}
                    className="club-icon"
                  />
                </button>
              )}
            </div>
            <button onClick={nextClub} className="carousel-button right">&#8250;</button>
          </div>
          {clubs.length === 0 && <p>No clubs available</p>}
          <button onClick={handleCheckInOtherClub} className="check-in-other-club-button">
            Check In Other Club
          </button>
        </div>
      )}
    </div>
  );
}

export default Profile;

